import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <>
      <div className="flex flex-col justify-center items-center text-tecnofi-text my-20">
        <h3 className="text-7xl font-semibold text-center">
          Not Found
        </h3>
        <p className="font-medium mt-2">
          Esta pagina no ha sido encontrada, por favor vuelva al index
        </p>
        <Link to={"/"} className="flex text-[#fff] my-2 bg-tecnofi-primary font-medium text-xl rounded-lg px-[16px] py-[5px] cursor-pointer active:bg-tecnofi-secondary">
          Index
        </Link>
      </div>
    </>
  );
};

export default NotFound;