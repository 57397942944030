import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Wrapper } from '../../../component';
import { ProductosDestacadosProps } from './types';
import { Product, Pagination } from '../../../component/fetch/types';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import useAuth from '../../../hooks/useAuth';

function ProductosDestacados({ isAuthenticated, toggleLogin }: ProductosDestacadosProps) {
  const { authToken } = useAuth();
  const [productos, setProductos] = useState<Product[]>([]);
  const [startIndex, setStartIndex] = useState(0);
  const brands = ["grandstream", "fanvil", "mikrotik", "ruijie", "tplink", "ubiquitinetworks", "hikvision"];

  useEffect(() => {
    //fetchProductsByBrands();
  }, []);

  const fetchProductsByBrands = async () => {
    const fetchedProducts: Product[] = [];

    for (const brand of brands) {
      try {
        const response = await axios.get<Pagination>(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/products`, {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          params: {
            cop: true,
            orden: "topseller",
            marca: brand
          }
        });

        if (response.data && response.data.products && response.data.products.length > 0) {
          const filteredProducts = response.data.products.slice(0, 2);
          fetchedProducts.push(...filteredProducts);
        } else {
          console.error(`Error fetching products for brand ${brand}: Response data or productos array is missing or empty`);
        }
      } catch (error: any) {
        console.error(`Error fetching products for brand ${brand}:`, error);
      }
    }

    setProductos(fetchedProducts);
  };

  const filterProducts = (): Product[] => {
    return productos.slice(startIndex, startIndex + 4);
  };

  const handleNextClick = () => {
    if (startIndex + 4 < productos.length) {
      setStartIndex(startIndex + 4);
    }
  };

  const handlePrevClick = () => {
    if (startIndex - 4 >= 0) {
      setStartIndex(startIndex - 4);
    }
  };

  const handleLogin = () => {
    if (toggleLogin) {
      toggleLogin();
    } else {
      console.error('toggleLogin function is not defined.');
    }
  }

  function formatCurrency(value: number | string | undefined): string {
    if (value === undefined) return '';
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;
    return numberValue.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
  }

  function calculateProfit(value: number | string | undefined): number {
    const numberValue = typeof value === 'string' ? parseFloat(value) : (value ?? 0);
    return numberValue * 1.20;
  }

  return (
    <Wrapper isContent>
      {productos && productos.length > 0 && (
        <>
          <h2 className="text-center text-2xl font-bold text-tecnofi-text mt-12">Productos Destacados</h2>
          <div className="flex justify-center items-center gap-4 mt-4 mb-12">
            <Button
              type="button"
              className="rounded-full p-[8px] bg-tecnofi-primary text-white"
              onClick={handlePrevClick}
              disabled={startIndex === 0}
            >
              <FaAngleLeft size="22" fill="#fff" />
            </Button>
            <section className="grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-3 mt-4">
              {filterProducts().map((producto: Product) => (
                <article
                  key={producto.id}
                  className="flex flex-col justify-between bg-tecnofi-section border-[2px] border-tecnofi-section rounded-lg text-xs text-tecnofi-text hover:drop-shadow-xl transition duration-100"
                >
                  <Link to={`/product/${producto.id}/${encodeURIComponent(producto.name)}`}>
                    <figure className="flex justify-center items-center rounded-t-md bg-tecnofi-background">
                      <img src={producto.images.product_url} alt={producto.name} className="h-24" />
                    </figure>
                    <div className="text-xs px-2 py-1 break-words">
                      <h2 className="font-bold line-clamp-3">{producto.name}</h2>
                      <p className="text-tecnofi-faded-text">{producto.model}</p>
                      {isAuthenticated && (
                        <p className="font-bold text-tecnofi-primary text-sm">
                          COP: {formatCurrency(calculateProfit(producto.price))}
                        </p>
                      )}
                      <p className="font-semibold text-tecnofi-faded-text hover:underline">{producto.brand}</p>

                    </div>
                  </Link>
                  {!isAuthenticated && (
                    <Button
                      variant="Primary"
                      className="w-full flex justify-center text-xs text-center rounded font-medium mx-auto mt-2 py-2 px-3"
                      onClick={handleLogin}
                    >
                      Acceder y Ver Precio
                    </Button>
                  )}
                </article>
              ))}
            </section>
            <Button
              type="button"
              className="rounded-full p-[8px] bg-tecnofi-primary text-white"
              onClick={handleNextClick}
              disabled={startIndex + 4 >= productos.length}
            >
              <FaAngleRight size="22" fill="#fff" />
            </Button>
          </div>
        </>
      )}
    </Wrapper>
  );
}

export default ProductosDestacados;
