import React from 'react';
import { Helmet } from 'react-helmet';
import GetOrder from './component/GetOrder';

function Orders() {
  return (
    <>
      <Helmet>
        <title>Tecnoficom | Órdenes</title>
        <meta name="description" content="Gestiona y realiza un seguimiento de tus órdenes de compra en Tecnoficom. Obtén una experiencia de compra fluida y segura, con opciones de pago flexibles y productos de calidad." />
        <meta name="keywords" content="Tecnoficom, órdenes de compra, gestión de órdenes, seguimiento de pedidos, productos de tecnología, ventas en línea, pagos seguros, soluciones tecnológicas, pedidos, servicios de infraestructura" />
      </Helmet>
      <h3 className="text-tecnofi-text text-lg font-semibold text-center mt-6 mb-2">
        Ordenes
      </h3>
      <GetOrder />
    </>
  );
};

export default Orders;