import React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

function AdminProducts() {
  return (
    <>
      <Helmet>
        <title>Tecnoficom | Administración de Productos</title>
        <meta
          name="description"
          content="Gestiona y organiza tus productos de manera eficiente con Tecnoficom. Optimiza la administración de inventarios, controla el estado de cada artículo, y facilita su actualización y seguimiento en tiempo real."
        />
        <meta
          name="keywords"
          content="Administración de productos, gestión de inventarios, control de productos, seguimiento de productos, actualización de productos, gestión de stock, plataforma de gestión de productos, organización de productos, Tecnoficom, eficiencia operativa"
        />
      </Helmet>
      <Outlet />
    </>
  );
};

export default AdminProducts;