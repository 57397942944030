import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { Button } from '../../../component';

function RecoveryPassword() {
  const { authToken } = useAuth();
  const location = useLocation();
  const urlSegments = location.pathname.split('/');
  const userId = urlSegments[urlSegments.indexOf('user') + 1];
  const queryParams = new URLSearchParams(location.search);
  const recoveryToken = queryParams.get('token');
  const [newPassword, setNewPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');

  const handleUserSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(userId, recoveryToken)
    if (!newPassword || !confirmation) {
      toast.error('Digite una contraseña.');
      return;
    }

    if (!userId || !recoveryToken) {
      toast.error('Error: el usuario o el token no están definidos.');
      return;
    }

    if (newPassword != confirmation) {
      toast.error('La contraseña es diferente.');
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/auth/change-password`,
        { password: newPassword, recoveryToken },
      );
      toast.success('¡Contraseña cambiada exitosamente!');
      setNewPassword('');
    } catch (error) {
      console.error('Error cambiando la contraseña:', error);
      toast.error('Error al cambiar la contraseña. Intenta nuevamente.');
    }
  };

  return (
    <section className="container text-tecnofi-text mx-auto p-4">
      <ToastContainer />
      <h2 className="text-tecnofi-text text-xl font-semibold text-center mt-6 mb-2">
        Recuperar contraseña
      </h2>
      <form onSubmit={handleUserSubmit} className="max-w-md mx-auto">
        <article className="flex flex-col mb-4">
          <label className="flex text-base font-medium mb-2" htmlFor="newPassword">
            Nueva contraseña
          </label>
          <input
            className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
            id="newPassword"
            type="password"
            placeholder="Nueva contraseña"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </article>

        <article className="flex flex-col mb-4">
          <label className="flex text-base font-medium mb-2" htmlFor="confirmation">
            Confirmar contraseña
          </label>
          <input
            className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
            id="confirmation"
            type="password"
            placeholder="Confirmar contraseña"
            value={confirmation}
            onChange={(e) => setConfirmation(e.target.value)}
            required
          />
        </article>

        <Button
          variant="Primary"
          type="submit"
          className="flex rounded-[8px] px-4 py-2 mx-auto"
        >
          Cambiar contraseña
        </Button>
      </form>
    </section>
  );
}

export default RecoveryPassword;
