import React from 'react';
import { Link } from 'react-router-dom';

interface LogoNavbarProps {
  className?: string;
}

function LogoNavbar({ className }: LogoNavbarProps) {
  return (
    <Link to={"/"} className="flex-shrink-0">
      <img src="/tecnoficom-logo.png" alt="Logo Tecnoficom" className={`hidden md:flex ${className}`} />
      <img src="/favicon.ico" alt="Logo Tecnoficom" className={`md:hidden h-[50px] w-[46px] ${className}`} />
    </Link>
  );
}

export default LogoNavbar;
