import React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import GetHistory from './pages/GetHistory';

function AdminOrders() {
  return (
    <>
      <Helmet>
        <title>Tecnoficom | Administración de Órdenes</title>
        <meta
          name="description"
          content="Administra tus órdenes de compra de manera eficiente con Tecnoficom. Supervisa, organiza y gestiona el estado de tus pedidos en tiempo real para una operación comercial optimizada."
        />
        <meta
          name="keywords"
          content="Administración de órdenes, gestión de pedidos, control de órdenes, seguimiento de órdenes, Tecnoficom, panel de administración, estado de pedidos, organización de órdenes, plataforma de gestión de compras, eficiencia operativa"
        />
      </Helmet>
      <GetHistory />
    </>
  );
};

export default AdminOrders;