import React, { useEffect } from 'react';
import { AsideBarProps, Product, Category } from './types';

function AsideBar({
  dataFilter,
  marcaFilters,
  categoriaFilters,
  minPrecio,
  maxPrecio,
  setMarcaFilters,
  setCategoriaFilters,
  setMinPrecio,
  setMaxPrecio,
  isAuthenticated
}: AsideBarProps) {

  useEffect(() => {
    dataFilter = [];
  }, []);

  let marcas: string[] = [];
  let categorias: string[] = [];

  dataFilter.forEach((product: Product) => {
    if (product.name && !marcas.includes(product.brand)) {
      marcas.push(product.brand);
    }
    if (product.categories && product.categories.length > 0) {
      product.categories.forEach((categoria: Category | string) => {
        if (typeof categoria === 'string') {
          if (!categorias.includes(categoria)) {
            categorias.push(categoria);
          }
        } else {
          if (!categorias.includes(categoria.nombre)) {
            categorias.push(categoria.nombre);
          }
        }
      });
    }
  });

  const handleMarcaChange = (marca: string) => {
    if (marcaFilters.includes(marca)) {
      setMarcaFilters(marcaFilters.filter((m) => m !== marca));
    } else {
      setMarcaFilters([...marcaFilters, marca]);
    }
  };

  const handleCategoriaChange = (categoria: string | Category) => {
    if (typeof categoria === 'string') {
      if (categoriaFilters.includes(categoria)) {
        setCategoriaFilters(categoriaFilters.filter((c) => c !== categoria));
      } else {
        setCategoriaFilters([...categoriaFilters, categoria]);
      }
    } else {
      if (categoriaFilters.includes(categoria.nombre)) {
        setCategoriaFilters(categoriaFilters.filter((c) => c !== categoria.nombre));
      } else {
        setCategoriaFilters([...categoriaFilters, categoria.nombre]);
      }
    }
  };

  const handleMinPrecioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMinPrecio(event.target.value);
  };

  const handleMaxPrecioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMaxPrecio(event.target.value);
  };

  return (
    <>
      {dataFilter.length > 0 && (
        <section className="hidden md:flex flex-col col-span-2 bg-tecnofi-section text-tecnofi-text text-xs rounded-lg px-6 py-6 lg:me-12 me-10">
          <article>
            <div className="mb-4">
              <h4 className="font-semibold text-sm text-center mb-2">Marcas</h4>
              {marcas.length === 0 || marcas.every(marca => marca && marca.trim() === "") ? (
                <div className="text-center text-sm text-gray-500">Sin Marca</div>
              ) : (
                marcas
                  .filter(marca => marca && marca.trim() !== "")
                  .map((marca) => (
                    <div key={marca} className="flex items-center my-1">
                      <input
                        type="checkbox"
                        id={marca}
                        checked={marcaFilters.includes(marca)}
                        onChange={() => handleMarcaChange(marca)}
                        className="hidden"
                      />
                      <label
                        htmlFor={marca}
                        className={`bg-tecnofi-primary font-medium text-[#fff] rounded-[4px] cursor-pointer select-none px-[12px] py-[6px] ${marcaFilters.includes(marca) ? 'bg-tecnofi-secondary' : ''}`}
                      >
                        {marca}
                      </label>
                    </div>
                  ))
              )}
            </div>
          </article>
          <article>
            <div className="mb-4">
              <h4 className="font-semibold text-sm text-center mb-2">Categorías</h4>
              {categorias.map((categoria) => (
                <div key={categoria} className="flex items-center my-1">
                  <input
                    type="checkbox"
                    id={categoria}
                    checked={categoriaFilters.includes(categoria)}
                    onChange={() => handleCategoriaChange(categoria)}
                    className="hidden"
                  />
                  <label
                    htmlFor={categoria}
                    className={`bg-tecnofi-primary font-medium text-[#fff] rounded-[4px] cursor-pointer select-none px-[12px] py-[6px] ${categoriaFilters.includes(categoria) ? 'bg-tecnofi-secondary' : ''}`}
                  >
                    {categoria}
                  </label>
                </div>
              ))}
            </div>
          </article>
          {isAuthenticated && (
            <article className="mb-4">
              <h4 className="font-semibold text-sm text-center">Precio</h4>
              <div className="flex flex-col items-start">
                <label htmlFor="minPrecio" className="mr-2">Min:</label>
                <input
                  type="text"
                  id="minPrecio"
                  value={minPrecio}
                  onChange={handleMinPrecioChange}
                  className="border-[2px] border-tecnofi-primary rounded-[4px] w-full py-1 px-2 bg-tecnofi-section focus:outline-none"
                />
                <label htmlFor="maxPrecio" className="mr-2">Max:</label>
                <input
                  type="text"
                  id="maxPrecio"
                  value={maxPrecio}
                  onChange={handleMaxPrecioChange}
                  className="border-[2px] border-tecnofi-primary rounded-[4px] w-full py-1 px-2 bg-tecnofi-section focus:outline-none"
                />
              </div>
            </article>
          )}
        </section>
      )}
    </>
  );
}

export default AsideBar;
