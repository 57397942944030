import React, { useEffect, useState } from 'react';
import { Button, Wrapper } from '../../../component';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { Wishlists } from './types';
import { Link } from 'react-router-dom';
import { MdOutlineRemoveCircle } from 'react-icons/md';

function GetWishlists() {
  const { authToken } = useAuth();
  const [wishlists, setWishlists] = useState<Wishlists>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const response = await axios.get<Wishlists>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/wishlists`,
          { headers }
        );
        setWishlists(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [authToken]);

  const removeProduct = async (id: string) => {
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/wishlists/remove/${id}`,
        {},
        { headers }
      );
      setWishlists((prevWishlists) => {
        if (!prevWishlists) return prevWishlists;
        const updatedProducts = prevWishlists.products.filter(product => product.id !== id);
        return { ...prevWishlists, products: updatedProducts };
      });
    } catch (error) {
      console.error("Error al eliminar el producto:", error);
    }
  };

  function formatCurrency(value: number | string | undefined): string {
    if (value === undefined) return '';
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;
    return numberValue.toLocaleString('es-CO', { style: 'currency', currency: 'COP' });
  }

  return (
    <Wrapper>
      {wishlists && (
        <section className="flex flex-col gap-2">
          {wishlists.products.map((product) => (
            <article key={product.id} className="flex justify-between items-center h-24 w-full bg-tecnofi-section text-tecnofi-text rounded-lg gap-2 px-2 py-2">
              <Link to={`/product/${product.id}/${encodeURIComponent(product.name)}`} className="flex gap-2">
                <img
                  src={product.images.product_url}
                  alt={product.name}
                  className="bg-[#fff] w-auto h-20 object-cover rounded-md"
                />
                <div className="flex flex-col">
                  <h3 className="text-sm font-normal line-clamp-2">{product.name}</h3>
                  <p className="text-sm text-tecnofi-faded-text">{product.model}</p>
                </div>
              </Link>
              <div className="flex flex-col justify-center items-end">
                <p className="text-tecnofi-primary font-bold md:ms-auto ms-0">{formatCurrency(product.price)}</p>

                <Button
                  type="button"
                  variant="Primary"
                  className="flex relative rounded-[8px] my-auto px-[6px] py-[4px]"
                  onClick={async () => await removeProduct(product.id)}
                >
                  <MdOutlineRemoveCircle size={26} fill="#FFF" />
                </Button>
              </div>
            </article>
          ))}
        </section>
      )}
    </Wrapper>
  );
}

export default GetWishlists;