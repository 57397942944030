import React, { useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { Button, Wrapper } from '../../../component';
import { toast } from 'react-toastify';

function UploadProduct() {
  const { authToken } = useAuth();
  const [productFile, setProductFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleExcelFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      // Verificar si el archivo es un Excel válido (.xlsx o .xls)
      if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || selectedFile.type === 'application/vnd.ms-excel') {
        setProductFile(selectedFile);
      } else {
        toast.warn("Por favor selecciona un archivo Excel válido (.xlsx o .xls)");
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!productFile) {
      toast.warn("Por favor selecciona un archivo Excel.");
      return;
    }

    const formData = new FormData();
    formData.append('productFile', productFile);

    setLoading(true);
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/products/upload`,
        formData,
        {
          headers: {
            ...headers,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      toast.success("Archivo Excel subido correctamente.");
      setProductFile(null);
    } catch (error) {
      toast.error("Algo falló al subir el archivo.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper isContent>
      <form onSubmit={handleSubmit}>
        <h3 className="text-lg font-semibold mt-4">Subir Archivo de Excel de Productos</h3>

        <div className="mb-4">
          <label className="flex text-base font-medium mb-2" htmlFor="product_file">
            Archivo Excel
          </label>
          <input
            className="hidden"
            id="product_file"
            type="file"
            onChange={handleExcelFileChange}
          />
          <label
            htmlFor="product_file"
            className="flex justify-center items-center border-[2px] border-tecnofi-primary rounded-[8px] text-tecnofi-faded-text cursor-pointer px-4 md:w-80 w-full h-40 bg-tecnofi-section"
          >
            {productFile ? (
              <div className="mt-2">
                <p className="text-tecnofi-text font-medium">{productFile.name}</p>
              </div>
            ) : (
              <p className="text-tecnofi-text font-medium">No file selected</p>
            )}
          </label>
        </div>

        <Button
          variant="Primary"
          type="submit"
          className="rounded-lg px-4 py-2"
          disabled={loading}
        >
          {loading ? "Subiendo..." : "Subir Archivo"}
        </Button>
      </form>
    </Wrapper>
  );
}

export default UploadProduct;