import React, { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { BannerProps } from './types';
import { Button, Wrapper } from '../../../component';

function Banner({ images, toggleLogin }: BannerProps) {
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const interval = setInterval(goToNext, 8000);
    return () => clearInterval(interval);
  }, []);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    if (imageRef.current) {
      gsap.fromTo(imageRef.current,
        { opacity: 0 },
        { opacity: 0.8, duration: 0.5, ease: 'power2.inOut' }
      );
    }
  }, [currentIndex]);

  const handleLoginButtonClick = (isLogin: boolean) => {
    toggleLogin(isLogin);
  };

  return (
    <figure className="relative bg-tecnofi-section overflow-hidden">
      <div className="flex justify-between items-center absolute z-10 inset-y-0 left-0 right-0 px-4">
        <button
          onClick={goToPrevious}
          className="bg-opacity-50 text-tecnofi-text text-4xl p-2 rounded-full hover:bg-opacity-75 focus:outline-none"
        >
          &lt;
        </button>
        <div className="flex flex-col justify-center items-center bg-opacity-50 bg-black text-[#fff]">
            <h2 className="font-semibold lg:text-3xl text-xl text-center mx-10">Registre su Empresa y acceda a precios y promociones Exclusivos!</h2>
            <div className="flex sm:gap-16 gap-4 mt-4">
              <Button
                variant="Primary"
                className="sm:max-w-[180px] max-w-[100px] w-full flex justify-center sm:text-[14px] text-[8px] text-center md font-medium mx-auto rounded-lg sm:py-2 sm:px-3 py-1 px-2"
                onClick={() => handleLoginButtonClick(true)}
              >
                Acceder
              </Button>
              <Button
                variant="Primary"
                className="sm:max-w-[180px] max-w-[100px] w-full flex justify-center sm:text-[14px] text-[8px] text-center font-medium mx-auto rounded-lg sm:py-2 sm:px-3 py-1 px-2"
                onClick={() => handleLoginButtonClick(false)}
              >
                Registrate
              </Button>
            </div>
          </div>
        <button
          onClick={goToNext}
          className="bg-opacity-50 text-tecnofi-text text-4xl p-2 rounded-full hover:bg-opacity-75 focus:outline-none"
        >
          &gt;
        </button>
      </div>
      <img
          ref={imageRef}
          src={images[currentIndex]}
          alt={`Imagen ${currentIndex}`}
          className="text-center object-cover mx-auto w-full xl:h-[780px] md:h-[500px] sm:h-[300px] xs:h-[260px] h-[200px]"
        />
    </figure>
  );
};

export default Banner;
