import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button } from '../../../component';

function ChangePassword() {
  const { getUserInfo, authToken } = useAuth();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleUserSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const user = getUserInfo();
    if (!user) {
      toast.error('Error: el usuario no está definido.');
      return;
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/auth/change-password`,
        { oldPassword, newPassword },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      toast.success('¡Contraseña cambiada exitosamente!');
      setOldPassword('');
      setNewPassword('');
    } catch (error) {
      console.error('Error cambiando la contraseña:', error);
      toast.error('Error al cambiar la contraseña. Intenta nuevamente.');
    }
  };

  return (
    <section className="container text-tecnofi-text mx-auto p-4">
      <h2 className="text-tecnofi-text text-xl font-semibold text-center mt-6 mb-2">
        Cambia contraseña
      </h2>
      <form onSubmit={handleUserSubmit} className="max-w-md mx-auto">
        <article className="flex flex-col mb-4">
          <label className="flex text-base font-medium mb-2" htmlFor="oldPassword">
            Contraseña actual
          </label>
          <input
            className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
            id="oldPassword"
            type="password"
            placeholder="Contraseña actual"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
        </article>
        <article className="flex flex-col mb-4">
          <label className="flex text-base font-medium mb-2" htmlFor="newPassword">
            Nueva contraseña
          </label>
          <input
            className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
            id="newPassword"
            type="password"
            placeholder="Nueva contraseña"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </article>
        <Button
          variant="Primary"
          type="submit"
          className="flex rounded-[8px] px-4 py-2 mx-auto"
        >
          Cambiar contraseña
        </Button>
      </form>
    </section>
  );
}

export default ChangePassword;