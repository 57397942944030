import React, { useState, useEffect } from 'react';
import { TiThMenu } from 'react-icons/ti';
import { IoMdSettings } from "react-icons/io";
import { HeaderProps } from './types';
import Button from '../button/Button';
import { LogoNavbar, Menu, Navbar, Searchbar, Settings, TMR } from './component';
import Wrapper from '../wrapper/Wrapper';

function Header({ toggleTheme, toggleLogin }: HeaderProps) {
  const [menu, setMenu] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        document.body.style.overflow = menu ? 'hidden' : '';
      } else {
        setMenu(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [menu]);

  const toggleMenu = () => {
    setMenu(!menu);
    setShowSettings(false);
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  return (
    <header className="bg-tecnofi-background lg:border-[0px] border-b-[3px] border-tecnofi-primary">
      <Menu isOpen={menu} toggleMenu={toggleMenu} className={menu ? 'block' : 'hidden'} toggleLogin={toggleLogin} toggleTheme={toggleTheme} toggleSettings={toggleSettings} />
      <Wrapper>
        <div className="flex relative justify-between items-center gap-[8px] py-[12px]">
          <LogoNavbar />
          <div className="flex items-center gap-1">
            <TMR className="lg:flex hidden" toggleTheme={toggleTheme} toggleLogin={toggleLogin} />
            <Button type="button" variant="Primary" className="flex relative rounded-[8px] px-[6px] py-[4px]" onClick={toggleSettings}>
              <IoMdSettings size={26} color="white" />
            </Button>
            <Button variant="Primary" className="flex my-auto lg:hidden rounded-[8px] px-[6px] py-[4px]" onClick={toggleMenu}>
              <TiThMenu size={26} fill="#FFF" />
            </Button>
            {showSettings && <Settings toggleTheme={toggleTheme} toggleLogin={toggleLogin} />}
          </div>
        </div>
      </Wrapper>
      <Wrapper isContent>
        <Searchbar className="hidden lg:block" toggleMenu={toggleMenu} toggleTheme={toggleTheme} toggleLogin={toggleLogin} />
      </Wrapper>
      <Navbar className="lg:block hidden" toggleTheme={toggleTheme} toggleLogin={toggleLogin} />
    </header>
  );
}

export default Header;