import React, { useState } from 'react';
import { Button, Wrapper } from '../../component';
import { Helmet } from 'react-helmet';
import GetShoppingCart from './component/GetShoppingCart';
import useAuth from '../../hooks/useAuth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

function ShoppingCart() {
  const { authToken } = useAuth();
  const navigate = useNavigate();
  const [totalCost, setTotalCost] = useState(0);
  const [toggleSelected, setToggleSelected] = useState(false);
  const [paymethod, setPaymethod] = useState('');

  const createOrder = async () => {
    if (!paymethod) {
      toast.error('Seleccione el método de pago.')
      return;
    }
    toast.info('Creando una orden.')
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      await axios.post(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/order`, { payMethod: paymethod }, { headers });
      navigate('/orders');
    } catch (error) {
      console.error("Error al crear la orden:", error);
    }
  };

  const createQuote = async () => {
    try {
      toast.info('Creando una cotizacion.')
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      await axios.post(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/quote`, {}, { headers });
      navigate('/quotes');
    } catch (error) {
      console.error("Error al crear la cotización:", error);
    }
  };

  const handleTotalCostChange = (newTotalCost: number) => {
    setTotalCost(newTotalCost);
  };

  const togglePaymethod = (type: string) => {
    setPaymethod(type);
    setToggleSelected(false);
  };

  return (
    <Wrapper>
      <ToastContainer />
      <Helmet>
        <title>Tecnoficom | Carrito de Compras</title>
        <meta name="description" content="Revisa y gestiona tus productos en el carrito de compras de Tecnoficom. Compra con comodidad y seguridad." />
        <meta name="keywords" content="Tecnoficom, carrito de compras, productos, ventas, precios, servicio, nacional, infraestructura, profesionales, técnicos, soluciones, conectividad, redes, tecnología, calidad" />
      </Helmet>
      <div className="grid grid-cols-6 py-12">
        <section className="md:col-span-4 sm:col-span-3 col-span-6">
          <article className="bg-tecnofi-background px-4 py-1">
            <h3 className="font-semibold text-xl text-tecnofi-text text-center">Carrito de Compras</h3>
            <GetShoppingCart onTotalCostChange={handleTotalCostChange} />
          </article>
        </section>
        <section className="flex flex-col bg-tecnofi-section rounded-lg md:col-span-2 sm:col-span-3 col-span-6">
          <article className="flex flex-col px-4 py-1 gap-4 my-4">
            <h3 className="text-xl text-tecnofi-text text-center font-semibold">Valor Total de los Productos</h3>
            <div className="text-center text-tecnofi-text font-medium">
              <p>COP {totalCost.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</p>
              <p className="text-center text-tecnofi-faded-text text-[10px] font-medium">Sin impuestos ni retenciones incluidas.</p>
            </div>
          </article>
          <hr className="bg-tecnofi-background text-tecnofi-background rounded-full h-[2px] w-[90%] mx-auto" />
          <article className="flex flex-col px-4 py-1 gap-4 my-4">
            <h3 className="text-xl text-tecnofi-text text-center font-semibold">Cotizacion</h3>
            <Button variant="Primary" className="flex justify-center text-[14px] text-center font-medium mx-auto rounded-lg py-2 px-3" onClick={createQuote}>
              GENERAR UNA COTIZACION
            </Button>
            <hr className="bg-tecnofi-background text-tecnofi-background rounded-full h-[2px] w-[90%] mx-auto" />
            <h3 className="text-xl text-tecnofi-text text-center font-semibold">Orden</h3>
            <div className="relative flex flex-col">
              <label
                className="flex justify-between items-center bg-tecnofi-section font-normal text-base text-tecnofi-text border-[2px] border-tecnofi-primary px-[16px] py-[8px] rounded-[8px] h-[52px] w-full cursor-pointer"
                onClick={() => setToggleSelected(!toggleSelected)}
              >
                {paymethod ? paymethod : 'Seleccione el Metodo de Pago'}
              </label>
              {toggleSelected && (
                <div className="absolute top-full left-0 right-0 bg-tecnofi-background border border-tecnofi-primary rounded-lg mt-1 z-50 overflow-hidden shadow-md">
                  <div className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section" onClick={() => togglePaymethod('CREDIT')}>
                    Crédito
                  </div>
                  <div className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section" onClick={() => togglePaymethod('DEBIT')}>
                    Débito
                  </div>
                  <div className="py-2 px-4 cursor-pointer hover:bg-tecnofi-section" onClick={() => togglePaymethod('BANK_TRANSFER')}>
                    Transferencia Bancaria
                  </div>
                </div>
              )}
            </div>
            <Button variant="Primary" className="flex justify-center text-[14px] text-center font-medium mx-auto rounded-lg py-2 px-3" onClick={createOrder}>
              GENERAR UNA ORDEN
            </Button>
          </article>
        </section>
      </div>
    </Wrapper>
  );
}

export default ShoppingCart;