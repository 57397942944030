import React from 'react';
import Wrapper from '../wrapper/Wrapper';
import { FooterProps } from './types';

function Footer({ togglePolitics }: FooterProps) {

	const handdlePolitics = () => {
		togglePolitics();
	}

	return (
		<footer className="bg-tecnofi-dark text-[#fff] pt-[8px] lg:pt-[22px] mt-auto">
			<Wrapper>
				<div className="grid md:grid-cols-2 grid-cols-1 gap-[32px] py-[20px]">
					<div className="text-center">
						<div className="flex justify-center mb-[20px]">
							<img src="/tecnoficom-logo.png" alt="Logo Tecnoficom"/>
						</div>
						<h5 className="text-lg font-normal">Nosotros</h5>
						<p className="text-sm font-light">
							Somos una empresa de profesionales y técnicos especializados en telecomunicaciones con el ánimo de brindar servicios que atiendan las necesidades del mercado posicionándonos a nivel nacional mediante la colaboración de nuestro recurso humano y la infraestructura de nuestra organización.
						</p>
					</div>
					<div className="self-center text-base font-semibold">
						<h5 className="text-center text-lg font-normal">Contáctenos</h5>
						<ul className="text-sm text-center">
							<li className="my-[15px]">
								<a href="https://wa.me/+573002178613" className="hover:underline">
									WhatsApp / PBX : 300 217 8613
								</a>
							</li>
							<li className="my-[15px]">
								Dirección : Carrera 11 No.64-54, Bogotá | Colombia
							</li>
							<li className="my-[15px]">
								<a href="mailto:info@tecnoficom.com" className="hover:underline">
									Correo : info@tecnoficom.com
								</a>
							</li>
							<li className="cursor-pointer hover:underline my-[15px]" onClick={handdlePolitics}>
								Politicas de Privacidad y Terminos y Condiciones
							</li>
						</ul>
					</div>
				</div>
			</Wrapper>
			<p className="bg-tecnofi-primary text-center text-[10px] font-bold mt-auto py-[8px]">
				Copyright 2024 <span className="">Tecnoficom</span>  | Diseñado por:
				<a href="https://www.tecnoficom.com"> Tecnoficom Ltda</a>
			</p>
		</footer>
	);
}

export default Footer;
