import React from 'react';
import { MdLogout, MdOutlineAccountCircle } from "react-icons/md";
import Button from "../../button/Button";
import LogoNavbar from "../../header/component/LogoNavbar";
import Wrapper from "../../wrapper/Wrapper";
import { Link } from 'react-router-dom';

function DashboardAside() {
  return (
    <aside className="hidden lg:flex bg-tecnofi-section text-tecnofi-text w-[16%]">
      <nav className="w-full px-4 py-3">
          <h3 className="flex justify-center text-tecnofi-text text-lg font-semibold text-center mb-2">
            Opciones
          </h3>
          <ul>
            <li className="hover:text-tecnofi-primary cursor-pointer mb-2">
              <Link to="/dashboard/clients" >
                Clientes
              </Link>
            </li>
            <li className="hover:text-tecnofi-primary cursor-pointer mb-2">
              <Link to="/dashboard/admin-products" >
                Productos
              </Link>
            </li>
            <li className="hover:text-tecnofi-primary cursor-pointer mb-2">
              <Link to="/dashboard/history" >
                Ordenes y Cotizaciones
              </Link>
            </li>
            <li className="hover:text-tecnofi-primary cursor-pointer mb-2">
              <Link to="/dashboard/reports" >
                Reportes
              </Link>
            </li>
          </ul>
        </nav>
    </aside>
  );
}

export default DashboardAside;
