import React from 'react';
import { Outlet } from 'react-router-dom';
import { DashboardAside, DashboardHeader } from '../component/dashboard';

export interface DashboardProps{
  toggleTheme: () => void;
  toggleLogin: () => void;
}

function DashboardLayout({ toggleTheme, toggleLogin }: DashboardProps) {
  return (
    <div className="flex flex-col min-h-screen">
      <DashboardHeader toggleTheme={toggleTheme} toggleLogin={toggleLogin} />
      <div className="flex flex-1 max-w-full bg-tecnofi-background">
        <DashboardAside />
        <main className="flex-1 lg:w-[82%] w-full pb-10">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
