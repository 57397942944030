import React from 'react';
import { Link } from 'react-router-dom';

function Dashboard() {
  return (
    <>
      <div className="flex flex-col justify-center items-center text-tecnofi-text my-20 px-2">
        <h3 className="text-3xl font-semibold text-center">
          Bienvenido a nuestro Dashboard Administrativo
        </h3>
        <p className="font-medium mt-2">
          Selecciona la herramienta para empezar a gestionar.
        </p>
      </div>
    </>
  );
};

export default Dashboard;