import React from 'react';
import { Footer, Header } from '../component';
import { GeneralLayoutProps } from './types';
import { Outlet } from 'react-router-dom';

function GeneralLayout ({ toggleTheme, toggleLogin, valueLogin, togglePolitics }: GeneralLayoutProps) {
  return (
    <div className="flex flex-col min-h-screen">
      <Header toggleTheme={toggleTheme} toggleLogin={toggleLogin} valueLogin={valueLogin} />
      <main className="flex-1 bg-tecnofi-background">
        <Outlet />
      </main>
      <Footer togglePolitics={togglePolitics} />
    </div>
  );
};

export default GeneralLayout;