import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavbarProps, ApiResponse } from './types';
import { toast } from 'react-toastify';

function TMR({ className }: NavbarProps) {
  const [exchangeRates, setExchangeRates] = useState<number>();

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        const response = await axios.get<ApiResponse>(`${process.env.REACT_APP_TMR}${formattedDate}`);
        if (response.data && response.data.data && typeof response.data.data.value === 'number') {
          setExchangeRates(response.data.data.value);
        } else {
          toast.error('No se logro obtener la TMR')
        }
      } catch (error: any) {
        console.error('Error fetching exchange rates:', error);
      }
    };

    fetchExchangeRates();
  }, []);

  return (
    <>
      {exchangeRates && (
        <span className={`${className} bg-tecnofi-primary text-[#fff] font-medium rounded-[8px] px-[14px] py-[5px] cursor-pointer select-none w-fit`}>
          TRM: $ {exchangeRates}
        </span>
      )}
    </>
  );
}

export default TMR;
