import React from 'react';

function Reports() {
  return (
    <>
      <h3 className="text-tecnofi-text text-lg font-semibold text-center mt-6 mb-2">
        Reportes
      </h3>
    </>
  );
};

export default Reports;