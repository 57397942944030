import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { FetchProductProps, Product, Pagination } from './types';
import Button from '../button/Button';
import useAuth from '../../hooks/useAuth';

function FetchProduct(
  {
    idBrand,
    idCategory,
    searchParams,
    isBrand = false,
    isCategory = false,
    isSearch = false,
    setDataFilter,
    marcaFilters,
    categoriaFilters,
    minPrecio,
    maxPrecio,
    descuentoOnly,
    isAuthenticated,
    toggleLogin
  }: FetchProductProps) {
  const { authToken } = useAuth();
  const [products, setProducts] = useState<Product[]>([]);
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [loading, setLoading] = useState(true);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    setPagina(1);
    setTotalPaginas(1);
    setDataFilter([]);
    setNoResults(false);
  }, [isBrand, isCategory, isSearch, idBrand, idCategory, searchParams]);

  useEffect(() => {
    setProducts([]);
    setLoading(true);
    if (isBrand) {
      fetchBrand(pagina);
    } else if (isCategory) {
      fetchCategory(pagina);
    } else if (isSearch) {
      fetchSearch(pagina);
    }
  }, [isBrand, isCategory, isSearch, idBrand, idCategory, searchParams, pagina]);

  const fetchBrand = async (page: number) => {
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const response = await axios.get<Pagination>(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/products`,
        {
          headers,
          params: { offset: page - 1, limit: 20, brandId: idBrand },
        }
      );
      console.log(response.data)
      setProducts(response.data.products);
      setTotalPaginas(response.data.totalPages);
      setDataFilter(response.data.products);
      setNoResults(response.data.products.length === 0);
    } catch (error: any) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCategory = async (page: number) => {
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const response = await axios.get<Pagination>(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/products`,
        {
          headers,
          params: { offset: page - 1, limit: 20, categoryId: idCategory },
        }
      );
      setProducts(response.data.products);
      setTotalPaginas(response.data.totalPages);
      setDataFilter(response.data.products);
      setNoResults(response.data.products.length === 0);
    } catch (error: any) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSearch = async (page: number) => {
    try {
      const params: { [key: string]: string | number } = { offset: page - 1, limit: 20 };
      if (searchParams) params.searchTerm = searchParams.split(' ').join('+');
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const response = await axios.get<Pagination>(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/products`,
        {
          params,
          headers,
        }
      );
      setProducts(response.data.products);
      setTotalPaginas(response.data.totalPages);
      setDataFilter(response.data.products);
      setNoResults(response.data.products.length === 0);
    } catch (error: any) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    setPagina((prevPagina) => Math.min(prevPagina + 1, totalPaginas));
  };

  const handlePreviousPage = () => {
    setPagina((prevPagina) => Math.max(prevPagina - 1, 1));
  };

  const handleLogin = () => {
    if (toggleLogin) {
      toggleLogin();
    }
  }

  const filterProducts = (): Product[] => {
    return products.filter((product) => {
      let marcaFiltered = false;
      let categoriaFiltered = false;
      if (
        marcaFilters?.length === 0 ||
        marcaFilters?.some((marca) => product.brand && product.brand.toLowerCase() === marca.toLowerCase())
      ) {
        marcaFiltered = true;
      }
      if (
        categoriaFilters?.length === 0 ||
        product.categories.some((cat) => categoriaFilters?.includes(cat))
      ) {
        categoriaFiltered = true;
      }
      const precioInRange =
        (!minPrecio || product.price >= +minPrecio) &&
        (!maxPrecio || product.price <= +maxPrecio);
      return marcaFiltered && categoriaFiltered && precioInRange;
    });
  };


  if (loading) {
    return (
      <section className="flex flex-col justify-center items-center col-span-6 text-tecnofi-text my-20">
        <h3 className="text-3xl font-semibold text-center mb-4">
          Cargando, por favor espera...
        </h3>
        <div className="flex space-x-2">
          <div className="w-3 h-3 bg-tecnofi-primary rounded-full animate-bounce delay-200 shadow-sm"></div>
          <div className="w-3 h-3 bg-tecnofi-primary rounded-full animate-bounce delay-1000 shadow-sm"></div>
          <div className="w-3 h-3 bg-tecnofi-primary rounded-full animate-bounce delay-1800 shadow-sm"></div>
        </div>
      </section>
    );
  }

  if (noResults) {
    return (
      <section className="flex flex-col justify-center items-center col-span-6 text-tecnofi-text my-20">
        <h3 className="text-7xl font-semibold text-center">
          Not Found
        </h3>
        <p className="font-medium mt-2">
          Esta página no ha sido encontrada, por favor vuelva al índice.
        </p>
        <Link to={"/"} className="flex text-[#fff] my-2 bg-tecnofi-primary font-medium text-xl rounded-lg px-[16px] py-[5px] cursor-pointer active:bg-tecnofi-secondary">
          Index
        </Link>
      </section>
    );
  }

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(price);
  };

  return (
    <div className="md:col-span-4 col-span-6 w-full h-full">
      {totalPaginas <= 1 ? null : (
        <section className="flex justify-between items-center font-medium mt-4">
          <Button
            type="button"
            className="rounded-lg p-[6px] bg-tecnofi-primary text-white"
            onClick={handlePreviousPage}
            disabled={pagina === 1}
          >
            <FaAngleLeft size="20" fill="#fff" />
          </Button>
          <span className="text-tecnofi-text text-center font-medium">
            Página {pagina} de {totalPaginas}
          </span>
          <Button
            type="button"
            className="rounded-lg p-[6px] bg-tecnofi-primary text-white"
            onClick={handleNextPage}
            disabled={pagina === totalPaginas}
          >
            <FaAngleRight size="20" fill="#fff" />
          </Button>
        </section>
      )}
      <section className="grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-3 mt-4">
        {filterProducts().map((product: Product) => (
          <article
            key={product.id}
            className="flex flex-col justify-between bg-tecnofi-section border-[2px] border-tecnofi-section rounded-lg text-xs text-tecnofi-text hover:drop-shadow-xl transition duration-100"
          >
            <Link to={`/product/${product.id}/${encodeURIComponent(product.name)}`}>
              <figure className="flex justify-center items-center rounded-t-md bg-tecnofi-background">
                <img
                  src={product?.images?.product_url ? product.images.product_url : '/favicon.ico'}
                  alt={product.name}
                  className={product?.images?.product_url ? "object-cover w-[300px] h-[200px] rounded-md" : "object-cover h-[50px] w-[50px] my-[75px]"}
                />
              </figure>
              <section className="flex flex-col px-3 py-2">
                <p className="font-semibold line-clamp-3">{product.name}</p>
                <p className="text-tecnofi-faded-text font-semibold">{product.model}</p>
                <p className="text-tecnofi-text font-semibold">{product.brand}</p>
                {isAuthenticated && (
                  <p className="text-tecnofi-primary text-base font-bold mt-2">COP $ {formatPrice(product.price)}</p>
                )}
              </section>
            </Link>
            {!isAuthenticated && (
              <Button
                variant="Primary"
                className="w-full flex justify-center text-xs text-center rounded font-medium mx-auto mt-2 py-2 px-3"
                onClick={handleLogin}
              >
                Acceder y Ver Precio
              </Button>
            )}
          </article>
        ))}
      </section>
    </div>
  );
}

export default FetchProduct;