import React, { useState, useCallback } from 'react';
import { Wrapper, Window } from '../../component';
import { Helmet } from 'react-helmet';
import GetWishlists from './component/GetWishlist';

function Wishlists() {
  return (
    <>
      <Helmet>
        <title>Tecnoficom | Listas de Deseos</title>
        <meta name="description" content="Explora y administra tus listas de deseos en Tecnoficom. Crea nuevas listas y actualiza las existentes según tus necesidades." />
        <meta name="keywords" content="Tecnoficom, listas de deseos, categorías, administrar listas, productos, personalizar, organizar, preferencias, guardar productos" />
      </Helmet>
      <Wrapper>
        <h3 className="text-tecnofi-text text-lg font-semibold text-center mt-6 mb-2">
          Lista de Deseos
        </h3>
        <GetWishlists />
      </Wrapper>
    </>
  );
}

export default Wishlists;
