import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { Button, Wrapper } from '../../../component';

function ForgottenPassword() {
  const [email, setEmail] = useState('');

  const handleUserSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      toast.error('El correo ingresado no es válido.');
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/auth/password-request`,
        { body: email }
      );
      toast.success('¡Contraseña cambiada exitosamente!');
      setEmail('');
    } catch (error) {
      console.error('Error cambiando la contraseña:', error);
      toast.error('Error al cambiar la contraseña. Intenta nuevamente.');
    }
  };

  return (
    <section className="container text-tecnofi-text mx-auto p-4">
      <ToastContainer />
      <Wrapper isContent>
        <h2 className="text-tecnofi-text text-xl font-semibold text-center mt-6 mb-2">
          Correo Electrónico
        </h2>
        <p className="text-tecnofi-text text-center mb-4">
          Por favor, ingresa tu correo electrónico. Enviaremos un mensaje para confirmar que eres el propietario y permitirte cambiar tu contraseña.
        </p>
        <form onSubmit={handleUserSubmit} className="max-w-md mx-auto">
          <article className="flex flex-col mb-4">
            <label className="flex text-base font-medium mb-2" htmlFor="email">
              Correo Electrónico
            </label>
            <input
              className="border-[2px] border-tecnofi-primary rounded-[8px] w-full py-3 px-4 bg-tecnofi-section focus:outline-none"
              id="email"
              type="email"
              placeholder="Correo Electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </article>

          <Button
            variant="Primary"
            type="submit"
            className="flex rounded-[8px] px-4 py-2 mx-auto"
          >
            Enviar Confirmación
          </Button>
        </form>
      </Wrapper>
    </section>
  );
}

export default ForgottenPassword;