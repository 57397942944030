import React, { useEffect, useState } from 'react';
import { AsideBar, Button, FetchProduct, MenuAsideBar, Wrapper } from '../../../component';
import { useParams } from 'react-router-dom';
import { Product } from '../../../component/fetch/types';
import { MdOutlineFilterAlt } from "react-icons/md";
import { CategoriesProps } from '../types';
import { Helmet } from 'react-helmet';

function ProductSubCategories({ isAuthenticated, toggleLogin }: CategoriesProps) {
  const [filters, setFilters] = useState<Product[]>([]);
  const [menuFilter, setMenuFilter] = useState(false);
  const { id, nombre } = useParams();

  const [marcaFilters, setMarcaFilters] = useState<string[]>([]);
  const [categoriaFilters, setCategoriaFilters] = useState<string[]>([]);
  const [minPrecio, setMinPrecio] = useState('');
  const [maxPrecio, setMaxPrecio] = useState('');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        document.body.style.overflow = menuFilter ? 'hidden' : '';
      } else {
        setMenuFilter(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [menuFilter]);

  const toggleFilter = () => {
    setMenuFilter(!menuFilter);
  };

  return (
    <div className="py-12">
       <Helmet>
        <title>Tecnoficom | {nombre ? nombre : 'Categoría'}</title>
        <meta name="description" content={`Descubre los mejores productos de ${nombre ? nombre : 'esta categoría'} en Tecnoficom. Explora nuestra amplia selección y encuentra lo que necesitas.`} />
        <meta name="keywords" content={`${nombre ? nombre : 'Categoría'}, Tecnoficom, productos, telecomunicaciones, ventas, precios, servicio, nacional, infraestructura, profesionales, técnicos, soluciones, conectividad, redes, tecnología, calidad`} />
      </Helmet>
      <MenuAsideBar
        className={menuFilter ? 'block' : 'hidden'}
        isOpen={menuFilter}
        toggleFilter={toggleFilter}
        dataFilter={filters}
        marcaFilters={marcaFilters}
        categoriaFilters={categoriaFilters}
        minPrecio={minPrecio}
        maxPrecio={maxPrecio}
        setMarcaFilters={setMarcaFilters}
        setCategoriaFilters={setCategoriaFilters}
        setMinPrecio={setMinPrecio}
        setMaxPrecio={setMaxPrecio}
        isAuthenticated={isAuthenticated}
      />
      <h2 className="text-tecnofi-text text-center text-3xl font-semibold mb-6 break-words">{nombre}</h2>
      <Wrapper>
        {filters && filters.length > 0 && (
          <Button variant="Primary" className="rounded-full md:hidden flex my-auto px-[16px] py-[5px]" onClick={toggleFilter}>
            <MdOutlineFilterAlt size={22} fill='#fff' />
          </Button>
        )}
        <div className="grid grid-cols-6">
          <AsideBar
            isOpen={menuFilter}
            toggleFilter={toggleFilter}
            dataFilter={filters}
            marcaFilters={marcaFilters}
            categoriaFilters={categoriaFilters}
            minPrecio={minPrecio}
            maxPrecio={maxPrecio}
            setMarcaFilters={setMarcaFilters}
            setCategoriaFilters={setCategoriaFilters}
            setMinPrecio={setMinPrecio}
            setMaxPrecio={setMaxPrecio}
            isAuthenticated={isAuthenticated}
          />
          <FetchProduct
            isCategory
            idCategory={id}
            setDataFilter={setFilters}
            marcaFilters={marcaFilters}
            categoriaFilters={categoriaFilters}
            minPrecio={minPrecio}
            maxPrecio={maxPrecio}
            isAuthenticated={isAuthenticated}
            toggleLogin={toggleLogin}
          />
        </div>
      </Wrapper>
    </div>
  );
}

export default ProductSubCategories;
