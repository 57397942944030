import { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';

export interface JwtPayload {
  id: string;
  email: string;
  legalPersonType: boolean;
  clientRoles: string[];
  clientStatus: string;
  clientType: string;
  profileId: string;
}

const useAuth = () => {
  const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem('authToken'));

  useEffect(() => {
    if (authToken) {
      verifyToken();
    }
  }, [authToken]);

  const verifyToken = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/auth/verify`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const { user, token } = response.data;
      if (token) {
        setAuthToken(token);
        localStorage.setItem('authToken', token);
      }
    } catch (error) {
      logout();
      console.error('Error al verificar el token:', error);
      toast.error('La sesión ha expirado. Por favor, inicia sesión de nuevo.');
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/auth/login`, { email, password });
      localStorage.setItem('authToken', response.data.token);
      setAuthToken(response.data.token);
      verifyToken();
      window.location.reload();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message;
        throw new Error(errorMessage);
      }
      throw new Error('Error al iniciar sesión');
    }
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setAuthToken(null);
    window.location.reload();
  };

  const isAuthenticated = () => !!authToken;

  const decodeToken = (): JwtPayload | null => {
    if (!authToken) return null;
    try {
      return jwtDecode<JwtPayload>(authToken);
    } catch (error) {
      console.error('Error al decodificar el token:', error);
      return null;
    }
  };

  const getUserInfo = () => {
    const decodedToken = decodeToken();
    if (decodedToken) {
      return {
        id: decodedToken.id,
        roles: decodedToken.clientRoles,
        status: decodedToken.clientStatus,
      };
    }
    return {
      roles: [],
      status: 'UNKNOWN',
    };
  };

  const isAdmin = (): boolean => {
    const decodedToken = decodeToken();
    if (decodedToken?.clientRoles) {
      return decodedToken.clientRoles.includes('ADMIN');
    }
    return false;
  };

  return { login, logout, isAuthenticated, getUserInfo, isAdmin, authToken };
};

export default useAuth;