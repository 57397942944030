import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Marca, FilterProps } from './types';
import axios from 'axios';
import useAuth from '../../../hooks/useAuth';

function Filter({ toggleMenu }: FilterProps) {
  const { authToken } = useAuth();
  const [brands, setBrands] = useState<Marca[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get<Marca[]>(`${process.env.REACT_APP_BACKEND_TECNOFICOM}/brand`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        const sortedBrands = response.data.sort((a, b) => a.name.localeCompare(b.name));
        setBrands(sortedBrands);
      } catch (error: any) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [authToken]);

  const handleLinkClick = () => {
    toggleMenu && toggleMenu();
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredBrands = brands.filter((brand) =>
    brand.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {brands && brands.length > 0 && (
        <div className="absolute rounded-[8px] z-40 border-[3px] border-tecnofi-primary bg-tecnofi-background lg:max-w-[500px] max-h-[500px] max-w-[300px] px-[20px] py-[12px] right-0 top-[40px] overflow-auto">
          <input
            type="text"
            placeholder="Buscar marca..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full px-3 py-2 mb-4 border-b-[3px] border-tecnofi-primary rounded-t-lg focus:outline-none"
          />

          {filteredBrands.map((brand) => (
            <Link
              key={brand.id}
              to={`/brand/${brand.id}/${encodeURIComponent(brand.name)}`}
              className="block text-tecnofi-text font-medium hover:text-tecnofi-primary hover:underline"
              onClick={handleLinkClick}
            >
              {brand.name}
            </Link>
          ))}
        </div>
      )}
    </>
  );
}

export default Filter;