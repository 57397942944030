import React, { useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { Wrapper } from '../../../component';
import { toast } from 'react-toastify';

function CreateImage() {
  const { authToken } = useAuth();
  const [productImage, setProductImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleProductImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setProductImage(files[0]);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!productImage) {
      toast.warn("Por favor selecciona una imagen.");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('productImage', productImage);

    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/files/product`,
        formData,
        {
          headers: {
            ...headers,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      toast.success("Imagen subida correctamente.");
      setImageUrl(response.data.url);
      setProductImage(null);
    } catch (error) {
      toast.error("Algo falló!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper isContent>
      <form className="" onSubmit={handleSubmit}>
        <h3 className="text-lg font-semibold mt-4">Subir Imagen de Producto</h3>

        <div className="mb-4">
          <label className="flex text-base font-medium mb-2" htmlFor="product_url">
            Imagen principal
          </label>
          <input
            className="hidden"
            id="product_url"
            type="file"
            onChange={handleProductImageChange}
          />
          <label
            htmlFor="product_url"
            className="flex justify-center items-center border-[2px] border-tecnofi-primary rounded-[8px] text-tecnofi-faded-text cursor-pointer px-4 md:w-80 w-full h-40 bg-tecnofi-section"
          >
            {productImage ? (
              <div className="mt-2">
                <img
                  src={productImage instanceof File ? URL.createObjectURL(productImage) : ''}
                  alt="Imagen seleccionada"
                  className="w-[100px] h-[auto] rounded-md"
                />
              </div>
            ) : (
              <p className="text-tecnofi-text font-medium">No image selected</p>
            )}
          </label>
        </div>

        <button
          type="submit"
          className="bg-tecnofi-primary text-[#fff] rounded p-2"
          disabled={loading}
        >
          {loading ? "Subiendo..." : "Subir Imagen"}
        </button>

        {imageUrl && (
          <div className="mt-4">
            <p className="text-tecnofi-text font-medium">URL de la imagen subida:</p>
            <a
              href={imageUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-tecnofi-primary underline"
            >
              {imageUrl}
            </a>
          </div>
        )}
      </form>
    </Wrapper>
  );
}

export default CreateImage;