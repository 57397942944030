import React, { useEffect, useState } from 'react';
import { Button, Wrapper } from '../../../component';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import { OrderHistory, HistoryItem } from './types';
import { MdOutlineSimCardDownload } from 'react-icons/md';

function GetHistory() {
  const { authToken } = useAuth();
  const [orderHistory, setOrderHistory] = useState<OrderHistory | null>(null);
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState<Record<string, string>>({});
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [pendingStatusChange, setPendingStatusChange] = useState<{ orderId: string; status: string } | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
        const response = await axios.get<OrderHistory>(
          `${process.env.REACT_APP_BACKEND_TECNOFICOM}/history`,
          { headers, params: { offset: pagina - 1, limit: 10 } }
        );
        setOrderHistory(response.data);
        setTotalPaginas(response.data.totalPages);
        const initialStatuses: Record<string, string> = {};
        response.data.history.forEach(order => {
          if (order.status) {
            initialStatuses[order.id] = order.status;
          }
        });
        setSelectedStatus(initialStatuses);

      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [authToken, pagina]);

  const download = async (id: string, type: string) => {
    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/${type}/${id}`,
        { headers, responseType: 'blob' }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `quote_${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      toast.success('Archivo descargado.');
    } catch (error) {
      toast.error('Hubo un problema al descargar el archivo.');
    }
  };

  const toggleStatusDropdown = (orderId: string) => {
    setOpenDropdown(openDropdown === orderId ? null : orderId);
  };

  const requestStatusChange = (orderId: string, status: string) => {
    setPendingStatusChange({ orderId, status });
    setOpenDropdown(null);
    setShowConfirmModal(true);
  };

  const confirmStatusChange = async () => {
    if (!pendingStatusChange) return;
    const { orderId, status } = pendingStatusChange;
    const currentStatus = selectedStatus[orderId];

    try {
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      await axios.patch(
        `http://localhost:5000/api/order/${orderId}`,
        { status },
        { headers }
      );
      setSelectedStatus((prev) => ({ ...prev, [orderId]: status }));
      toast.success('Estado actualizado exitosamente.');
    } catch (error) {
      toast.error('Error al actualizar el estado.');
    } finally {
      setPendingStatusChange(null);
      setShowConfirmModal(false);
    }
  };

  const handleNextPage = () => {
    if (pagina < totalPaginas) {
      setPagina((prevPagina) => prevPagina + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pagina > 1) {
      setPagina((prevPagina) => prevPagina - 1);
    }
  };

  return (
    <Wrapper>
      <h3 className="text-tecnofi-text text-lg font-semibold text-center mt-6 mb-2">
        Historial de Ordenes
      </h3>
      <ToastContainer />
      {totalPaginas > 1 && (
        <section className="flex justify-between items-center font-medium my-4">
          <Button
            type="button"
            className="rounded-full p-[8px] bg-tecnofi-primary text-white"
            onClick={handlePreviousPage}
            disabled={pagina === 1}
          >
            <FaAngleLeft size="22" fill="#fff" />
          </Button>
          <span className="text-tecnofi-text text-center font-medium">
            Página {pagina} de {totalPaginas}
          </span>
          <Button
            type="button"
            className="rounded-full p-[8px] bg-tecnofi-primary text-white"
            onClick={handleNextPage}
            disabled={pagina === totalPaginas}
          >
            <FaAngleRight size="22" fill="#fff" />
          </Button>
        </section>
      )}
      {orderHistory ? (
        <section>
          {orderHistory.history.map((order: HistoryItem) => (
            <article key={order.id} className="flex flex-col bg-tecnofi-section text-tecnofi-text p-4 mb-4 rounded-lg shadow-md">
              <div className="flex justify-between items-center">
                <h4 className="text-lg font-semibold">{order.type === 'quote' ? 'Quote' : 'Order'} ID: {order.customId}</h4>
                <div className="flex justify-center items-center gap-2">
                  {order.status && (
                    <div className="relative">
                      <label
                        className="flex justify-between items-center bg-tecnofi-section text-tecnofi-text border border-tecnofi-primary px-[12px] py-[4px] rounded-lg cursor-pointer"
                        onClick={() => toggleStatusDropdown(order.id)}
                      >
                        {selectedStatus[order.id] || 'Seleccione el Estado'}
                      </label>
                      {openDropdown === order.id && (
                        <div
                          className="absolute top-0 right-0 bg-tecnofi-background border border-tecnofi-primary rounded-lg mt-1 z-50 shadow-md w-[200px]"
                          style={{ top: "100%", minWidth: "250px" }}
                        >
                          <div className="py-2 px-4 cursor-pointer rounded-t-lg hover:bg-tecnofi-section" onClick={() => requestStatusChange(order.id, 'PENDING')}>
                            Pendiente
                          </div>
                          <div className="py-2 px-4 cursor-pointer rounded-lg hover:bg-tecnofi-section" onClick={() => requestStatusChange(order.id, 'COMPLETED')}>
                            Completado
                          </div>
                          <div className="py-2 px-4 cursor-pointer rounded-lg hover:bg-tecnofi-section" onClick={() => requestStatusChange(order.id, 'CANCELED')}>
                            Cancelado
                          </div>
                          <div className="py-2 px-4 cursor-pointer rounded-lg hover:bg-tecnofi-section" onClick={() => requestStatusChange(order.id, 'PROCESSING')}>
                            Procesando
                          </div>
                          <div className="py-2 px-4 cursor-pointer rounded-b-lg hover:bg-tecnofi-section" onClick={() => requestStatusChange(order.id, 'SENT')}>
                            Enviado
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <Button
                    variant="Primary"
                    className="flex relative rounded-[4px] px-[4px] py-[4px]"
                    onClick={() => download(order.id, order.type)}
                  >
                    <MdOutlineSimCardDownload size={22} fill="#FFF" />
                  </Button>
                </div>
              </div>
              <div className="grid md:grid-cols-3 sm:grid-cols-2 mt-2">
                <p className="text-sm"><strong>Total:</strong> {order.totalPrice.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</p>
                <p className="text-sm"><strong>Total Items:</strong> {order.totalItems}</p>
                <p className="text-sm"><strong>Creada:</strong> {new Date(order.createdAt).toLocaleString()}</p>
                {order.payMethod && <p className="text-sm"><strong>Metodo de pago:</strong> {order.payMethod}</p>}
                {order.paidAt && (
                  <p className="text-sm"><strong>Pagada el:</strong> {new Date(order.paidAt).toLocaleString()}</p>
                )}
              </div>
            </article>
          ))}
        </section>
      ) : (
        <p className="text-center text-tecnofi-text">Cargando órdenes...</p>
      )}
      {showConfirmModal && (
        <div className="fixed z-50 inset-0 bg-gray-800 bg-[#4444] flex justify-center items-center">
          <div className="bg-tecnofi-background p-4 rounded-lg shadow-md">
            <h2 className="text-tecnofi-primary text-lg font-bold mb-4">Confirmación</h2>
            <p>¿Estás seguro de que deseas cambiar el estado de {selectedStatus[pendingStatusChange?.orderId || '']} a {pendingStatusChange?.status}?</p>
            <div className="flex justify-end gap-2 mt-4">
              <Button className="rounded-[4px] my-auto px-[8px] py-[4px]" onClick={() => setShowConfirmModal(false)}>Cancelar</Button>
              <Button className="rounded-[4px] my-auto px-[8px] py-[4px]" variant="Primary" onClick={confirmStatusChange}>Confirmar</Button>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
}

export default GetHistory;
