import React, { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { Pagination, Product } from './types';
import { Button, Wrapper } from '../../../component';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { MdOutlineCreate } from 'react-icons/md';
import { IoMdImage } from 'react-icons/io';
import { PiMicrosoftExcelLogoBold } from 'react-icons/pi';

function GetAllProduct() {
  const { authToken } = useAuth();
  const [products, setProducts] = useState<Product[]>([]);
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [loading, setLoading] = useState(true);
  const [noResults, setNoResults] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [dataFilter, setDataFilter] = useState<Product[]>([]);
  const [searchParams, setSearchParams] = useState<string | null>(null);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        setSearchParams(searchTerm);
        fetchSearch(pagina);
      } else {
        fetchProducts(pagina);
      }
    }, 400);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, pagina]);

  const fetchProducts = async (page: number) => {
    try {
      setLoading(true);
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const response = await axios.get<Pagination>(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/products`,
        {
          headers,
          params: { offset: page - 1, limit: 30 },
        }
      );
      setProducts(response.data.products);
      setTotalPaginas(response.data.totalPages);
      setNoResults(response.data.products.length === 0);
    } catch (error: any) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSearch = async (page: number) => {
    try {
      setLoading(true);
      const params: { [key: string]: string | number } = { offset: page - 1, limit: 20 };
      if (searchParams) params.searchTerm = searchParams.split(' ').join('+');
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
      const response = await axios.get<Pagination>(
        `${process.env.REACT_APP_BACKEND_TECNOFICOM}/products`,
        {
          params,
          headers,
        }
      );
      setProducts(response.data.products);
      setTotalPaginas(response.data.totalPages);
      setDataFilter(response.data.products);
      setNoResults(response.data.products.length === 0);
    } catch (error: any) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    setPagina((prevPagina) => Math.min(prevPagina + 1, totalPaginas));
  };

  const handlePreviousPage = () => {
    setPagina((prevPagina) => Math.max(prevPagina - 1, 1));
  };

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(price);
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    setPagina(1);
    setSearchParams(searchTerm);
  };

  return (
    <Wrapper>
      <h3 className="flex-1 text-tecnofi-text text-lg font-semibold text-center mt-4 mb-2">
        Administración de Productos
      </h3>
      <section className="flex justify-start items-center w-full gap-2 m-2">
        <span className="flex items-center gap-2">
          <Link
            to={`/dashboard/admin-products/create`}
            className="bg-tecnofi-primary rounded-[8px] p-[8px] text-white"
          >
            <MdOutlineCreate size="16" fill="#fff" />
          </Link>
          <p className="text-tecnofi-text text-base">
            Crear Producto
          </p>
        </span>
        <span className="flex items-center gap-2">
          <Link
            to={`/dashboard/admin-products/images`}
            className="bg-tecnofi-primary rounded-[8px] p-[6px] text-white"
          >
            <IoMdImage size="20" fill="#fff" />
          </Link>
          <p className="text-tecnofi-text text-base">
            Imagenes
          </p>
        </span>
        <span className="flex items-center gap-2">
          <Link
            to={`/dashboard/admin-products/upload`}
            className="bg-tecnofi-primary rounded-[8px] p-[6px] text-white"
          >
            <PiMicrosoftExcelLogoBold size="20" fill="#fff" />
          </Link>
          <p className="text-tecnofi-text text-base">
            Subir un Archivo
          </p>
        </span>
      </section>
      <form onSubmit={handleSearch} className="flex justify-between items-center border-[3px] border-tecnofi-primary relative bg-tecnofi-background w-full rounded-[8px] px-[4px] py-[2px] gap-[2px]">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="bg-tecnofi-background text-tecnofi-text w-full rounded-[8px] focus:outline-none font-medium font-poppins placeholder-text-tecnofi-faded-text px-[8px] py-[4px]"
          placeholder="Search"
        />
      </form>
      <div className="md:col-span-4 col-span-6 w-full">
        {totalPaginas <= 1 ? null : (
          <section className="flex justify-between items-center font-medium mt-4">
            <Button
              type="button"
              className="rounded-lg p-[6px] bg-tecnofi-primary text-white"
              onClick={handlePreviousPage}
              disabled={pagina === 1}
            >
              <FaAngleLeft size="22" fill="#fff" />
            </Button>
            <span className="text-tecnofi-text text-center font-medium">
              Página {pagina} de {totalPaginas}
            </span>
            <Button
              type="button"
              className="rounded-lg p-[6px] bg-tecnofi-primary text-white"
              onClick={handleNextPage}
              disabled={pagina === totalPaginas}
            >
              <FaAngleRight size="22" fill="#fff" />
            </Button>
          </section>
        )}
        <section className="grid xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-3 mt-4">
          {(searchTerm ? dataFilter : products).map((product: Product) => (
            <article
              key={product.id}
              className="flex flex-col justify-between bg-tecnofi-section border-[2px] border-tecnofi-section rounded-lg text-xs text-tecnofi-text hover:drop-shadow-xl transition duration-100"
            >
              <Link to={`/dashboard/admin-products/${product.id}`}>
                <figure className="flex justify-center items-center rounded-t-md bg-tecnofi-background">
                  <img
                    src={product?.images?.product_url ? product.images.product_url : '/favicon.ico'}
                    alt={product.name}
                    className={product?.images?.product_url ? "object-cover h-[150px] w-[150px]" : "object-cover h-[50px] w-[50px] my-[50px]"}
                  />
                </figure>
                <section className="flex flex-col px-3 py-2">
                  <p className="font-semibold line-clamp-3">{product.name}</p>
                  <p className="text-tecnofi-faded-text font-semibold">{product.model}</p>
                  <p className="text-tecnofi-text font-semibold">{product.brand}</p>
                  <p className="text-tecnofi-primary text-base font-bold mt-2">
                    COP $ {formatPrice(product.price)}
                  </p>
                </section>
              </Link>
            </article>
          ))}
        </section>
      </div>
    </Wrapper>
  );
}

export default GetAllProduct;